<template>
  <div>
    <search-header
      :currentActive="detail && detail.id == '1428530876595646465' ? 9 : 8"
    ></search-header>
    <div v-if="detail" class="bg-light">
      <div class="container">
        <div class="row">
          <nav class="col-12 p-0 mt-3">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/news">资讯首页</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="'/news/lists?id=' + detail.contentType">{{
                  getNewsClassValue
                }}</router-link>
              </li>
              <li class="breadcrumb-item" active>资讯详情</li>
            </ol>
          </nav>
          <div class="col-8 bg-white my-2">
            <div class="px-3 py-4">
              <div class="h4 text-center">{{ detail.title }}</div>
              <div class="text-secondary text-center">
                <span v-if="detail.startTime">{{ detail.startTime.split("T")[0] }}</span>
                <span class="ml-3"
                  >作者：{{ detail.author ? detail.author : "本站" }}</span
                >
                <span class="ml-3">浏览量：{{ detail.browseNum }}</span>
              </div>
              <hr />
              <div class="content" v-html="detail.content"></div>
              <div class="row mt-4 justify-content-between">
                <div class="col-6">
                  <div class="mb-2 d-flex align-items-center">
                    <div>分享至</div>
                    <share class="ml-2" :config="shareConfig"></share>
                  </div>
                </div>
                <div class="col-2 clearfix">
                  <div class="mb-2 float-right">
                    <a href="javascript:;" class="clearfix"  @click="onCollection" :class="{ 'text-theme': isCollectionShow }">
                      <span class="float-left">
                        <svg
                          class="bi bi-star-fill float-right mr-1"
                          width="20"
                          height="20"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                          />
                        </svg>
                      </span>
                      <span class="float-right">收藏</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div v-if="tjList.length > 0" class="bg-white my-2 px-3 py-3">
              <div class="h6 mb-3">小编推荐</div>
              <div v-for="(m, i) in tjList" :key="i" class="text-truncate mt-1">
                <router-link :to="'/news/detail?id=' + m.id" target="_blank">{{
                  m.title
                }}</router-link>
                <hr v-if="tjList.length > i + 1" class="mt-2 mb-2" />
              </div>
            </div>
            <div class="bg-white my-2 px-3 py-3">
              <div class="h6 mb-3">推荐土地</div>
              <div class="row">
                <div class="col-6 mb-3" v-for="m in landList" :key="m.id">
                  <land-right :datas="m"></land-right>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LandRight from "@/components/right/land-right.vue";

export default {
  components: {
    LandRight,
  },
  data() {
    return {
      detail: null,
      tjList: [],
      landList: [],
      relationId: "",
      isCollectionShow: false,
      shareConfig: {
        url                 : 'http://www.51cqjy.com/#/news/detail?id=', // 网址，默认使用 window.location.href
        source              : '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        title               : '', // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
        description         : '', // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
        image               : '', // 图片, 默认取网页中第一个img标签
        sites               : ['qq', 'weibo','wechat'], // 启用的站点
        wechatQrcodeTitle   : '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper  : '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      }
    };
  },
  computed: {
    ...mapGetters(["user","dict"]),
    getNewsClassValue() {
      if (this.dict && this.detail) {
        if (this.dict.newsClass && this.detail.contentType) {
          let n = this.dict.newsClass.find(
            (x) => x.id == this.detail.contentType
          );
          if (n) {
            return n.text;
          }
        }
      }
      return "";
    },
  },
  methods: {
    getDetail() {
      if (this.$route.query.id > 0) {
        this.$http
          .get(this.$api.news.getDetail + "/" + this.$route.query.id)
          .then((res) => {
            debugger;
            this.detail = res.result;
            this.shareConfig.url+=this.$route.query.id;
            this.shareConfig.title = this.detail.title;
            this.shareConfig.description = this.detail.digest;
            this.shareConfig.source = this.detail.author == '' ? '农村综合产权交易服务平台' : this.detail.author;
            this.shareConfig.image = this.detail.thumbnail;
            const regex = new RegExp(`<img [^>]*src=['"]([^'"]+)[^>]*>`, "gi");
            let _this = this;
            this.detail.content = this.detail.content.replace(
              regex,
              function (match, capture) {
                return capture.includes("http://") ||
                  capture.includes("https://")
                  ? match
                  : match.replace(
                      capture,
                      _this.$global.picBaseUrl + capture.replace("../", "")
                    );
              }
            );
            this.getTjList();
            this.getCollection();
          });
      }
    },
    getCollection() {
      //查询用户是否收藏资讯
      if (this.$test.number(this.detail.id) && this.user) {
        this.$http.post(this.$api.user.findCollection, {param: { relationId: this.detail.id, type:2 }})
        .then((res) => {
          if (res.code == 1) {
            let a = res.result;
            if (a.length > 0) {
              this.relationId = a[0].id;
              this.isCollectionShow = true;
            } else {
              this.isCollectionShow = false;
            }
          }
        });
      }
    },
    getTjList() {
      if (this.detail.contentType > 0) {
        this.$http
          .post(this.$api.news.getList, {
            param: {
              contentType: this.detail.contentType,
              showMode: 4,
            },
            size: 8,
            current: 1,
          })
          .then((res) => {
            this.tjList = res.result.records;
          });
      }
    },
    getLandList() {
      this.$http
        .post(this.$api.supply.getNeedsForLetList, {
          param: {},
          size: 6,
          current: 1,
        })
        .then((res) => {
          this.landList = res.result.records.map((x) => {
            let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
            return {
              id: x.id,
              fileUrl: f ? f.fileUrl : "",
              title: x.title,
              price: x.price,
              priceUnit: x.priceUnit,
              measureArea: x.measureArea,
              acreageUnit: x.acreageUnit,
            };
          });
        });
    },
    onCollection() {
      if(this.user) {
        if(this.isCollectionShow) {
          this.$http
          .delete(this.$api.user.deleteCollection + this.relationId)
          .then((res) => {
            this.isCollectionShow = false;
          });
        } else {
          this.$http.post(this.$api.user.addCollection, 
            {
              relationId: this.detail.id,
              type: 2
            }
          )
          .then((res) => {
            this.isCollectionShow = true;
            this.relationId = res.result.id;
          });
        }
      } else {
        let u = this.$router.resolve({path: '/login'});
        window.open(u.href, '_blank');
      }
      
    },
  },
  created() {
    this.getDetail();
    this.getLandList();
  },
};
</script>

<style scoped>
</style>
